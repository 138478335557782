<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules" class="hide">
      <a-form-model-item label="申请人" prop="userName"  class="hide">
        <a-input v-model="form.userName" :readOnly="readOnly" />
      </a-form-model-item>
      <a-form-model-item label="抬头名称" prop="userName"  class="hide">
        <a-input v-model="form.titleName" :readOnly="readOnly" />
      </a-form-model-item>
      <a-form-model-item label="抬头类型" prop="titleType"   >
        <a-select  style="width: 100%" v-model="form.titleType" allowClear class="hide" disabled >
          <a-select-option v-for="(item, index) in this.customDict.InvoiceTitleTypeEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="mail"  class="hide">
        <a-input v-model="form.mail" :readOnly="readOnly" />
      </a-form-model-item>
      <a-form-model-item label="税号" prop="companyTax"  class="hide">
        <a-input v-model="form.companyTax" :readOnly="readOnly" />
      </a-form-model-item>
      <a-form-model-item label="订单编号" prop="orderId"  class="hide">
        <a-input v-model="form.orderId" :readOnly="readOnly" />
      </a-form-model-item>
      <a-form-model-item label="开票金额" prop="amount"   class="hide" >
        <a-input v-model="form.amount" placeholder="请输入开票金额" :readOnly="readOnly"/>
      </a-form-model-item>
      <a-form-model-item label="开票状态" prop="status"   class="hide">
        <a-select placeholder="请选择状态" style="width: 100%" v-model="form.status" allowClear :readOnly="readOnly" disabled>
          <a-select-option v-for="(item, index) in this.customDict.InvoiceApplyStatusEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark" class="hide">
        <a-input v-model="form.remark" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="发票图片" prop="invoiceImg" >
        <a-upload
          name="invoiceImg"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :disabled="readOnly"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.invoiceImg"
            :src="form.invoiceImg"
            alt="cover"
            style="height: 200px; width: 200px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm" v-if="form.status===1">
            开票
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApply, addApply, updateApply } from '@/api/invoice/apply'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import {fileUpload} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      readOnly:false,
      loading: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        titleType: null,
        invoiceUserId: null,
        invoiceImg: null,
        companyTax: null,
        mail: null,
        orderId: null,
        userName: null,
        titleName: null,
        amount: null,

        invoiceInfo: null,

        status: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        invoiceImg: [
          { required: true, message: '发票不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.readOnly = false,
      this.form = {
        id: null,
        invoiceUserId: null,
        orderId: null,
        amount: null,
        invoiceInfo: null,
        status: 0,
        createTime: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApply({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    handleDetail(row, ids,readOnly) {
      this.reset()
      this.formType = 2
      this.readOnly = readOnly
      const id = row ? row.id : ids
      getApply({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'invoice'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'invoiceImg', res)
          _this.$message.success('上传成功')
        })
      })
    },
    // oss 上传结束
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApply(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addApply(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}


</script>
<style scoped lang="less">
.hide {
  /deep/ .ant-input {
    border: 0 !important;
  }
}
</style>
